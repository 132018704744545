<script>
  let currentYear = new Date().getFullYear();
</script>

<footer class="footer-copyright white py-3 text-center">
  <a href="http://bremp.com"> bremp </a> © {currentYear}
  <p>
    <i>Laziness production</i>
  </p>
</footer>
