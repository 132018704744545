<script>
  import { Col, Container, Row } from "sveltestrap";
  import CardItem from "./CardItem.svelte";

  const projects = [
    {
      title: "Cintcalc",
      description: "Credit card interest calculator.",
      image: "img/cintcalc.png",
      url: "https://cintcalc.firebaseapp.com",
    },
    {
      title: "Reroi",
      description: "Real-estate return on investment calculator.",
      image: "img/reroi.png",
      url: "https://reroi-dd158.firebaseapp.com",
    },
    {
      title: "Wish List",
      description: "Submit a wish to hopefully be fulfilled by angels.",
      image: "img/wishlist.png",
      url: "https://wishlist-100.web.app",
    },
    {
      title: "Dailyword",
      description:
        "Expand your vocabulary with words given at specified frequency.",
      image: "img/dailyword.png",
      url: "https://dailyword-1.web.app",
    },
    {
      title: "Live Wall",
      description: "A live wallpaper.",
      image: "img/livewall.png",
      url: "https://livewall-1.web.app",
    },
  ];
</script>

<Container>
  <h3>Projects</h3>
  <br />
  <Row>
    {#each projects as project}
      <Col><CardItem {...project} /></Col>
    {/each}
  </Row>
</Container>
