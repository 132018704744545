<script>
  export let title;
  export let description;
  export let image;
  export let url;
</script>

<div class="card">
  <img src={image} class="card-img-top" alt={title} />
  <div class="card-body">
    <h5 class="card-title">{title}</h5>
    <p class="card-text">
      {description}
    </p>
    <a href={url} class="btn btn-primary" target="_blank">Go</a>
  </div>
</div>
